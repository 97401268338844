import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import { Input, message } from "antd";
// import { Modal, message } from "antd";
import Button from "app/components/ui/button";
import Label from "app/components/ui/label";
import MainLayout from "app/components/ui/layout";
import Loader from "app/components/ui/loader";
import Modal from "app/components/ui/modal";
import { PRICINS } from "app/graphql/queries";
import { BUY_CREDITS } from "app/graphql/subscriptions/mutations";
import clsx from "clsx";
import { useRef, useState } from "react";

const pluralize = (str, count) => `${str}${count > 1 ? "s" : ""}`;

interface CreditSubscriptionItemProps {
  index: number;
  name: string;
  price: number;
  unit: number;
  text: string;
  label: string;
  onChoose: (name: string) => void;
}

function CreditSubscriptionItem({ index, name, unit, price, text, label, onChoose }: CreditSubscriptionItemProps) {
  const handleChoose = () => {
    onChoose(name);
  };

  return (
    <div
      className={clsx(
        "relative max-w-[300px] w-full shadow-md rounded-[4px] px-6 py-10 h-[350dpx]  flex  flex-col text-center gap-4 justify-between bg-white border-tertiary"
      )}
    >
      {/* <div className="text-lg font-semibold uppercase text-primary">
        {unit} {pluralize("crédit", unit)}
      </div> */}
      <div className="text-3xl font-semibold uppercase text-primary">{label}</div>
      <div className="py-5 text-2xl font-bold text-black">{price} FCFA</div>

      <div className="text-center">{text}</div>
      <Button
        onClick={handleChoose}
        block
        variant="contained"
        color="primary"
        className="!p-6 !bg-secondary !border-secondary"
      >
        J&apos;achète
      </Button>
    </div>
  );
}

function Subscriptions(props: RouteComponentProps) {
  const { data, loading: pricingLoading } = useQuery(PRICINS);
  const [buyCredits, { loading }] = useMutation(BUY_CREDITS);
  const [cgvCheckBox, setCGV] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [selectedOption, setOption] = useState(null);
  const ref = useRef(null);

  const handleConfirm = () => {
    if (!cgvCheckBox) {
      message.error("Vous devez valider les CGV pour être redirigé vers la page de paiement");
      return;
    }

    buyCredits({ variables: { input: { name: selectedOption, coupon: ref?.current?.input?.value } } })
      .then((res) => {
        if (res?.data?.result?.paymentUrl) {
          const tab = window.open(res.data.result.paymentUrl, "_blank");
          if (!tab) {
            window.location.href = res.data.result.paymentUrl;
          }
        } else if (res?.data?.result?.freeCredit) {
          message.success("Crédit accordé avec succès");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          message.error("Une erreur s'est produite");
        }
      })
      .catch(({ message: errorMsg }) => {
        message.error(errorMsg);
      });
  };

  const handleClose = () => {
    setConfirmModal(false);
  };

  if (pricingLoading) {
    return <Loader />;
  }

  return (
    <MainLayout>
      <div className="flex flex-col h-full">
        <div className="p-10 space-y-5 text-center text-white bg-primary">
          <h1 className="text-2xl font-medium text-center">Choisissez parmi nos offres</h1>
          {/* <p className="italic">* Un crédit correspond à un téléchargement</p> */}
          <p className="m-auto lg:w-1/2">
            Votre réussite n’est pas une question de prix. Profitez de tous nos modèles de CV et des multiples
            fonctionnalités de l’outil pour décrocher le job de vos rêves.
          </p>
        </div>

        <div className="xl:w-[70%] w-full m-auto py-10 ">
          <div className="grid grid-cols-1 gap-10 p-5 m-auto text-black md:grid-cols-2 place-items-center">
            {data?.result.map((plan, index) => (
              <CreditSubscriptionItem
                index={index}
                key={plan.id}
                name={plan.id}
                unit={plan.credits}
                price={plan.price}
                text={plan.description}
                label={plan.name}
                onChoose={(name) => {
                  setOption(name);
                  setConfirmModal(true);
                }}
              />
            ))}
          </div>
          <div className="p-5 text-center">
            <a href="/cgv" target="_blank" className="underline">
              Conditions Générales de Ventes
            </a>
          </div>
        </div>
      </div>

      <Modal
        visible={showConfirmModal}
        title="Voulez-vous poursuivre votre achat ?"
        onOk={handleConfirm}
        onCancel={handleClose}
        okText="Oui"
        cancelText="Non"
      >
        <div>
          <div>Vous serez redirigé vers notre page de paiement</div>

          <div className="py-4">
            <Label>Avez-vous un coupon de réduction ?</Label>
            <Input ref={ref} placeholder="Coupon de réduction" />
          </div>
          <div className="flex justify-center gap-2 py-4 text-center">
            <input
              type="checkbox"
              checked={cgvCheckBox}
              onChange={(e) => {
                setCGV(e.target.checked);
              }}
            />
            <a
              href="https://www.moncvpro.com/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
              className="text-sm underline"
            >
              Vous devez valider les CGV pour être redirigé vers la page de paiement
            </a>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}

export default Subscriptions;
