import { gql } from "@apollo/client";

export const BUY_CREDITS = gql`
  mutation buyCredits($input: BuyCreditsInput!) {
    result: buyCredits(input: $input) {
      paymentUrl
      freeCredit
    }
  }
`;
